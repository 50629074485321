<template>
  <div :class="['flex', {xs12: isExportable, md6: isExportable, size: isExportable}]">

    <vac-card
      v-if="isExportable"
      :title="$t('Impressions per Retailer Stats')"
      class="chart-widget">

      <ExcelPreviewModal
        v-if="isPreview"
        :data="preparedData"
        header="Impressions per Retailer Stats"
        @disable="togglePreview"
        @exportToExcel="exportToExcel"
      />
      <Actions
        v-if="isExportable"
        :active-switcher-button="activeSwitcherButton"
        :export-to-excel="exportToExcel"
        @togglePreview="togglePreview"
        is-preview
        @toggle-percentage="onToggle($event)"
      />

      <Bar
        :chart-id="'bar-chart'"
        :dataset-id-key="'label'"
        :chart-data="chartData"
        :css-classes="'bar export'"
        :chart-options="chartOptions"
      />
    </vac-card>

    <vac-card
      v-else
      :style="{margin: '10px 0'}"
      :title="$t('Impressions per Retailer Stats')"
      class="chart-widget">

      <Bar
        :chart-id="'bar-chart'"
        :dataset-id-key="'label'"
        :chart-data="chartData"
        :css-classes="'bar-small export'"
        :chart-options="chartOptions"
      />
    </vac-card>

  </div>
</template>

<script>
import VaChart from '../statistics-template/charts/va-charts/VaChart';
import HelperExcel from '../../services/Helpers/HelperExcel';
import Actions from "../actions/Actions";
import ExcelPreviewModal from "@/components/actions/ExcelPreviewModal";
import {Bar} from 'vue-chartjs/legacy'

import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, ArcElement } from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, ArcElement)

export default {
  name: 'RetailerStats',
  props: {
    data: {
      type: Object,
      default: () => {
      },
    },
    showValuesInLabels: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: "xl6",
    },
    isExportable: {
      type: Boolean,
      default: true
    }
  },
  components: {
    Actions,
    ExcelPreviewModal,
    Bar
  },
  data() {
    return {
      isPreview: false,
      activeSwitcherButton: 1
    }
  },
  computed: {
    chartData() {
      if (this.showValuesInLabels) {
        let mappedDatasets = this.data.datasets.map(ds => {
          return {
            backgroundColor: ds.backgroundColor,
            borderColor: ds.borderColor,
            data: ds.data,
            label: `${ds.label}: ${ds.data[0]}`
          }
        })
        return {
          labels: this.data.labels,
          datasets: mappedDatasets
        }
      } else {
        return this.data
      }
    },
    chartOptions() {
      return {
        responsive: true,
        maintainAspectRatio: false,
        indexAxis: 'y',
        plugins: {
          tooltip: {
            mode: 'index',
          },
          legend: {
            position: 'bottom',
            labels: {
              padding: 20,
              usePointStyle: true,
              pointStyle: 'circle'
            }
          },
        }
      };
    },
    preparedData () {
      const {datasets} = this.data;

      const returnObj = {}
      returnObj.labels = ["Impressions per Retailer Stats", "Count"]
      returnObj.maxLength = []

      for (let key = 0; key < returnObj.labels.length; key++) {
        returnObj.maxLength[key] = returnObj.labels[key].length;
      }

      returnObj.datasets = [];
      datasets.forEach(data => {
        data.data.forEach(info => {
          const obj = {
            data: [data.label, info]
          };
          returnObj.maxLength[0] = Math.max(returnObj.maxLength[0], String(data.label).length)
          returnObj.maxLength[1] = Math.max(returnObj.maxLength[1], String(info).length)
          returnObj.datasets.push(obj);
        });
      });

      return returnObj;
    }
  },
  methods: {
    exportToExcel() {
      const customData = [];
      const customLabels = ["Retailer", "Count"];
      const {datasets} = this.data;

      datasets.forEach(data => {
        const obj = {
          "Retailer": data.label,
          "Count": data.data[0],
        };
        customData.push(obj);
      });

      HelperExcel.exportToExcel({}, "Retailers Impressions", customLabels, customData);
    },
    togglePreview() {
      this.isPreview = !this.isPreview;
    },
    onToggle(val) {
      this.activeSwitcherButton = val
    }
  },
};
</script>

<style lang="scss">
.bar {
  height: 500px;
}

.bar-small {
  height: 400px;
}
</style>
